*, ::before, ::after {
  --tw-border-opacity: 1;
  border-color: rgba(229, 231, 235, var(--tw-border-opacity))
}

.container {
  width: 100%
}

@media (min-width: 640px) {
  .container {
    max-width: 640px
  }
}

@media (min-width: 768px) {
  .container {
    max-width: 768px
  }
}

@media (min-width: 1024px) {
  .container {
    max-width: 1024px
  }
}

@media (min-width: 1280px) {
  .container {
    max-width: 1280px
  }
}

@media (min-width: 1536px) {
  .container {
    max-width: 1536px
  }
}

.visible {
  visibility: visible !important
}

.static {
  position: static !important
}

.fixed {
  position: fixed !important
}

.absolute {
  position: absolute !important
}

.relative {
  position: relative !important
}

.sticky {
  position: sticky !important
}

.inset-0 {
  top: 0px !important;
  right: 0px !important;
  bottom: 0px !important;
  left: 0px !important
}

.top-2 {
  top: 0.5rem !important
}

.-top-2 {
  top: -0.5rem !important
}

.top-1\/2 {
  top: 50% !important
}

.right-0 {
  right: 0px !important
}

.right-2 {
  right: 0.5rem !important
}

.bottom-16 {
  bottom: 4rem !important
}

.left-0 {
  left: 0px !important
}

.left-2 {
  left: 0.5rem !important
}

.left-1\/2 {
  left: 50% !important
}

.z-10 {
  z-index: 10 !important
}

.order-first {
  -webkit-box-ordinal-group: -9998 !important;
      -ms-flex-order: -9999 !important;
          order: -9999 !important
}

.m-0 {
  margin: 0px !important
}

.m-1 {
  margin: 0.25rem !important
}

.m-4 {
  margin: 1rem !important
}

.mx-auto {
  margin-left: auto !important;
  margin-right: auto !important
}

.my-2 {
  margin-top: 0.5rem !important;
  margin-bottom: 0.5rem !important
}

.my-3 {
  margin-top: 0.75rem !important;
  margin-bottom: 0.75rem !important
}

.my-8 {
  margin-top: 2rem !important;
  margin-bottom: 2rem !important
}

.mt-1 {
  margin-top: 0.25rem !important
}

.mt-2 {
  margin-top: 0.5rem !important
}

.mt-3 {
  margin-top: 0.75rem !important
}

.mt-4 {
  margin-top: 1rem !important
}

.mt-6 {
  margin-top: 1.5rem !important
}

.mt-9 {
  margin-top: 2.25rem !important
}

.mr-1 {
  margin-right: 0.25rem !important
}

.mr-2 {
  margin-right: 0.5rem !important
}

.mr-4 {
  margin-right: 1rem !important
}

.mb-1 {
  margin-bottom: 0.25rem !important
}

.mb-2 {
  margin-bottom: 0.5rem !important
}

.mb-3 {
  margin-bottom: 0.75rem !important
}

.mb-4 {
  margin-bottom: 1rem !important
}

.mb-6 {
  margin-bottom: 1.5rem !important
}

.mb-8 {
  margin-bottom: 2rem !important
}

.mb-3\.5 {
  margin-bottom: 0.875rem !important
}

.ml-1 {
  margin-left: 0.25rem !important
}

.ml-2 {
  margin-left: 0.5rem !important
}

.ml-3 {
  margin-left: 0.75rem !important
}

.block {
  display: block !important
}

.inline-block {
  display: inline-block !important
}

.inline {
  display: inline !important
}

.flex {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important
}

.table {
  display: table !important
}

.grid {
  display: grid !important
}

.contents {
  display: contents !important
}

.hidden {
  display: none !important
}

.h-2 {
  height: 0.5rem !important
}

.h-4 {
  height: 1rem !important
}

.h-5 {
  height: 1.25rem !important
}

.h-7 {
  height: 1.75rem !important
}

.h-8 {
  height: 2rem !important
}

.h-10 {
  height: 2.5rem !important
}

.h-12 {
  height: 3rem !important
}

.h-20 {
  height: 5rem !important
}

.h-24 {
  height: 6rem !important
}

.h-36 {
  height: 9rem !important
}

.h-auto {
  height: auto !important
}

.h-px {
  height: 1px !important
}

.h-2\.5 {
  height: 0.625rem !important
}

.h-4\/5 {
  height: 80% !important
}

.h-full {
  height: 100% !important
}

.max-h-24 {
  max-height: 6rem !important
}

.w-2 {
  width: 0.5rem !important
}

.w-4 {
  width: 1rem !important
}

.w-5 {
  width: 1.25rem !important
}

.w-7 {
  width: 1.75rem !important
}

.w-8 {
  width: 2rem !important
}

.w-10 {
  width: 2.5rem !important
}

.w-12 {
  width: 3rem !important
}

.w-16 {
  width: 4rem !important
}

.w-20 {
  width: 5rem !important
}

.w-28 {
  width: 7rem !important
}

.w-72 {
  width: 18rem !important
}

.w-px {
  width: 1px !important
}

.w-4\/5 {
  width: 80% !important
}

.w-11\/12 {
  width: 91.666667% !important
}

.w-full {
  width: 100% !important
}

.min-w-0 {
  min-width: 0px !important
}

.max-w-sm {
  max-width: 24rem !important
}

.max-w-2xl {
  max-width: 42rem !important
}

.max-w-full {
  max-width: 100% !important
}

.flex-1 {
  -webkit-box-flex: 1 !important;
      -ms-flex: 1 1 0% !important;
          flex: 1 1 0% !important
}

.flex-4 {
  -webkit-box-flex: 4 !important;
      -ms-flex: 4 1 0% !important;
          flex: 4 1 0% !important
}

.flex-shrink-0 {
  -ms-flex-negative: 0 !important;
      flex-shrink: 0 !important
}

.flex-shrink {
  -ms-flex-negative: 1 !important;
      flex-shrink: 1 !important
}

.flex-grow-0 {
  -webkit-box-flex: 0 !important;
      -ms-flex-positive: 0 !important;
          flex-grow: 0 !important
}

.flex-grow {
  -webkit-box-flex: 1 !important;
      -ms-flex-positive: 1 !important;
          flex-grow: 1 !important
}

.transform {
  --tw-translate-x: 0 !important;
  --tw-translate-y: 0 !important;
  --tw-rotate: 0 !important;
  --tw-skew-x: 0 !important;
  --tw-skew-y: 0 !important;
  --tw-scale-x: 1 !important;
  --tw-scale-y: 1 !important;
  -webkit-transform: translateX(var(--tw-translate-x)) translateY(var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y)) !important;
      -ms-transform: translateX(var(--tw-translate-x)) translateY(var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y)) !important;
          transform: translateX(var(--tw-translate-x)) translateY(var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y)) !important
}

.-translate-x-1\/2 {
  --tw-translate-x: -50% !important
}

.translate-y-0 {
  --tw-translate-y: 0px !important
}

.translate-y-8 {
  --tw-translate-y: 2rem !important
}

.-translate-y-1\/2 {
  --tw-translate-y: -50% !important
}

@-webkit-keyframes spin {
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg)
  }
}

@keyframes spin {
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg)
  }
}

@-webkit-keyframes ping {
  75%, 100% {
    -webkit-transform: scale(2);
            transform: scale(2);
    opacity: 0
  }
}

@keyframes ping {
  75%, 100% {
    -webkit-transform: scale(2);
            transform: scale(2);
    opacity: 0
  }
}

@-webkit-keyframes pulse {
  50% {
    opacity: .5
  }
}

@keyframes pulse {
  50% {
    opacity: .5
  }
}

@-webkit-keyframes bounce {
  0%, 100% {
    -webkit-transform: translateY(-25%);
            transform: translateY(-25%);
    -webkit-animation-timing-function: cubic-bezier(0.8,0,1,1);
            animation-timing-function: cubic-bezier(0.8,0,1,1)
  }

  50% {
    -webkit-transform: none;
            transform: none;
    -webkit-animation-timing-function: cubic-bezier(0,0,0.2,1);
            animation-timing-function: cubic-bezier(0,0,0.2,1)
  }
}

@keyframes bounce {
  0%, 100% {
    -webkit-transform: translateY(-25%);
            transform: translateY(-25%);
    -webkit-animation-timing-function: cubic-bezier(0.8,0,1,1);
            animation-timing-function: cubic-bezier(0.8,0,1,1)
  }

  50% {
    -webkit-transform: none;
            transform: none;
    -webkit-animation-timing-function: cubic-bezier(0,0,0.2,1);
            animation-timing-function: cubic-bezier(0,0,0.2,1)
  }
}

.animate-bounce {
  -webkit-animation: bounce 1s infinite !important;
          animation: bounce 1s infinite !important
}

.cursor-pointer {
  cursor: pointer !important
}

.cursor-not-allowed {
  cursor: not-allowed !important
}

.select-none {
  -webkit-user-select: none !important;
     -moz-user-select: none !important;
      -ms-user-select: none !important;
          user-select: none !important
}

.resize-y {
  resize: vertical !important
}

.resize {
  resize: both !important
}

.grid-cols-2 {
  grid-template-columns: repeat(2, minmax(0, 1fr)) !important
}

.flex-col {
  -webkit-box-orient: vertical !important;
  -webkit-box-direction: normal !important;
      -ms-flex-direction: column !important;
          flex-direction: column !important
}

.flex-col-reverse {
  -webkit-box-orient: vertical !important;
  -webkit-box-direction: reverse !important;
      -ms-flex-direction: column-reverse !important;
          flex-direction: column-reverse !important
}

.flex-wrap {
  -ms-flex-wrap: wrap !important;
      flex-wrap: wrap !important
}

.items-start {
  -webkit-box-align: start !important;
      -ms-flex-align: start !important;
          align-items: flex-start !important
}

.items-end {
  -webkit-box-align: end !important;
      -ms-flex-align: end !important;
          align-items: flex-end !important
}

.items-center {
  -webkit-box-align: center !important;
      -ms-flex-align: center !important;
          align-items: center !important
}

.justify-start {
  -webkit-box-pack: start !important;
      -ms-flex-pack: start !important;
          justify-content: flex-start !important
}

.justify-end {
  -webkit-box-pack: end !important;
      -ms-flex-pack: end !important;
          justify-content: flex-end !important
}

.justify-center {
  -webkit-box-pack: center !important;
      -ms-flex-pack: center !important;
          justify-content: center !important
}

.justify-between {
  -webkit-box-pack: justify !important;
      -ms-flex-pack: justify !important;
          justify-content: space-between !important
}

.gap-0 {
  gap: 0px !important
}

.gap-1 {
  gap: 0.25rem !important
}

.gap-2 {
  gap: 0.5rem !important
}

.gap-3 {
  gap: 0.75rem !important
}

.gap-4 {
  gap: 1rem !important
}

.gap-0\.5 {
  gap: 0.125rem !important
}

.self-center {
  -ms-flex-item-align: center !important;
      align-self: center !important
}

.overflow-hidden {
  overflow: hidden !important
}

.overflow-y-auto {
  overflow-y: auto !important
}

.overflow-ellipsis {
  text-overflow: ellipsis !important
}

.whitespace-nowrap {
  white-space: nowrap !important
}

.break-words {
  overflow-wrap: break-word !important
}

.break-all {
  word-break: break-all !important
}

.rounded {
  border-radius: 0.25rem !important
}

.rounded-md {
  border-radius: 0.375rem !important
}

.rounded-lg {
  border-radius: 0.5rem !important
}

.rounded-xl {
  border-radius: 0.75rem !important
}

.rounded-2xl {
  border-radius: 1rem !important
}

.rounded-full {
  border-radius: 9999px !important
}

.rounded-b-md {
  border-bottom-right-radius: 0.375rem !important;
  border-bottom-left-radius: 0.375rem !important
}

.border-0 {
  border-width: 0px !important
}

.border {
  border-width: 1px !important
}

.border-t {
  border-top-width: 1px !important
}

.border-l-4 {
  border-left-width: 4px !important
}

.border-l {
  border-left-width: 1px !important
}

.border-solid {
  border-style: solid !important
}

.border-transparent {
  border-color: transparent !important
}

.border-gray-200 {
  --tw-border-opacity: 1 !important;
  border-color: rgba(229, 231, 235, var(--tw-border-opacity)) !important
}

.border-gray-300 {
  --tw-border-opacity: 1 !important;
  border-color: rgba(209, 213, 219, var(--tw-border-opacity)) !important
}

.border-glass-white-border {
  border-color: rgba(255, 255, 255, 0.2) !important
}

.border-orange-400 {
  --tw-border-opacity: 1 !important;
  border-color: rgba(247, 172, 60, var(--tw-border-opacity)) !important
}

.border-orange-500 {
  --tw-border-opacity: 1 !important;
  border-color: rgba(237, 137, 22, var(--tw-border-opacity)) !important
}

.border-green-blue-400 {
  --tw-border-opacity: 1 !important;
  border-color: rgba(10, 180, 160, var(--tw-border-opacity)) !important
}

.hover\:border-glass-white-bg-hover:hover {
  border-color: rgba(255, 255, 255, 0.4) !important
}

.focus\:border-orange-500:focus {
  --tw-border-opacity: 1 !important;
  border-color: rgba(237, 137, 22, var(--tw-border-opacity)) !important
}

.focus\:border-green-blue-400:focus {
  --tw-border-opacity: 1 !important;
  border-color: rgba(10, 180, 160, var(--tw-border-opacity)) !important
}

.bg-transparent {
  background-color: transparent !important
}

.bg-white {
  --tw-bg-opacity: 1 !important;
  background-color: rgba(255, 255, 255, var(--tw-bg-opacity)) !important
}

.bg-gray-100 {
  --tw-bg-opacity: 1 !important;
  background-color: rgba(243, 244, 246, var(--tw-bg-opacity)) !important
}

.bg-gray-200 {
  --tw-bg-opacity: 1 !important;
  background-color: rgba(229, 231, 235, var(--tw-bg-opacity)) !important
}

.bg-gray-300 {
  --tw-bg-opacity: 1 !important;
  background-color: rgba(209, 213, 219, var(--tw-bg-opacity)) !important
}

.bg-gray-400 {
  --tw-bg-opacity: 1 !important;
  background-color: rgba(156, 163, 175, var(--tw-bg-opacity)) !important
}

.bg-green-700 {
  --tw-bg-opacity: 1 !important;
  background-color: rgba(4, 120, 87, var(--tw-bg-opacity)) !important
}

.bg-glass-white-bg {
  background-color: rgba(255, 255, 255, 0.3) !important
}

.bg-orange-50 {
  --tw-bg-opacity: 1 !important;
  background-color: rgba(255, 247, 231, var(--tw-bg-opacity)) !important
}

.bg-orange-100 {
  --tw-bg-opacity: 1 !important;
  background-color: rgba(254, 237, 200, var(--tw-bg-opacity)) !important
}

.bg-orange-400 {
  --tw-bg-opacity: 1 !important;
  background-color: rgba(247, 172, 60, var(--tw-bg-opacity)) !important
}

.bg-green-blue-50 {
  --tw-bg-opacity: 1 !important;
  background-color: rgba(240, 253, 250, var(--tw-bg-opacity)) !important
}

.bg-green-blue-100 {
  --tw-bg-opacity: 1 !important;
  background-color: rgba(224, 249, 244, var(--tw-bg-opacity)) !important
}

.bg-green-blue-400 {
  --tw-bg-opacity: 1 !important;
  background-color: rgba(10, 180, 160, var(--tw-bg-opacity)) !important
}

.hover\:bg-gray-200:hover {
  --tw-bg-opacity: 1 !important;
  background-color: rgba(229, 231, 235, var(--tw-bg-opacity)) !important
}

.hover\:bg-glass-white-bg-hover:hover {
  background-color: rgba(255, 255, 255, 0.4) !important
}

.hover\:bg-orange-400:hover {
  --tw-bg-opacity: 1 !important;
  background-color: rgba(247, 172, 60, var(--tw-bg-opacity)) !important
}

.hover\:bg-orange-500:hover {
  --tw-bg-opacity: 1 !important;
  background-color: rgba(237, 137, 22, var(--tw-bg-opacity)) !important
}

.hover\:bg-cyan-400:hover {
  --tw-bg-opacity: 1 !important;
  background-color: rgba(0, 184, 214, var(--tw-bg-opacity)) !important
}

.hover\:bg-green-blue-500:hover {
  --tw-bg-opacity: 1 !important;
  background-color: rgba(5, 155, 138, var(--tw-bg-opacity)) !important
}

.bg-opacity-50 {
  --tw-bg-opacity: 0.5 !important
}

.bg-orange-gradient {
  background-image: -webkit-gradient(linear, left top, right top, from(#F2994A), to(#EEAA3D)) !important;
  background-image: linear-gradient(90deg, #F2994A 0%, #EEAA3D 100%) !important
}

.bg-transparent-to-white-to-transparent {
  background-image: -webkit-gradient(linear, left top, left bottom, from(transparent), color-stop(50%, white), to(transparent)) !important;
  background-image: linear-gradient(to bottom, transparent 0%, white 50%, transparent 100%) !important
}

.bg-transparent-to-white-to-transparent-horizontal {
  background-image: -webkit-gradient(linear, left top, right top, from(transparent), color-stop(50%, white), to(transparent)) !important;
  background-image: linear-gradient(to right, transparent 0%, white 50%, transparent 100%) !important
}

.object-cover {
  -o-object-fit: cover !important;
     object-fit: cover !important
}

.p-0 {
  padding: 0px !important
}

.p-1 {
  padding: 0.25rem !important
}

.p-2 {
  padding: 0.5rem !important
}

.p-3 {
  padding: 0.75rem !important
}

.p-4 {
  padding: 1rem !important
}

.p-5 {
  padding: 1.25rem !important
}

.p-6 {
  padding: 1.5rem !important
}

.px-2 {
  padding-left: 0.5rem !important;
  padding-right: 0.5rem !important
}

.px-3 {
  padding-left: 0.75rem !important;
  padding-right: 0.75rem !important
}

.px-4 {
  padding-left: 1rem !important;
  padding-right: 1rem !important
}

.px-6 {
  padding-left: 1.5rem !important;
  padding-right: 1.5rem !important
}

.py-1 {
  padding-top: 0.25rem !important;
  padding-bottom: 0.25rem !important
}

.py-2 {
  padding-top: 0.5rem !important;
  padding-bottom: 0.5rem !important
}

.py-3 {
  padding-top: 0.75rem !important;
  padding-bottom: 0.75rem !important
}

.py-4 {
  padding-top: 1rem !important;
  padding-bottom: 1rem !important
}

.pt-5 {
  padding-top: 1.25rem !important
}

.pr-2 {
  padding-right: 0.5rem !important
}

.pr-3 {
  padding-right: 0.75rem !important
}

.pb-3 {
  padding-bottom: 0.75rem !important
}

.pb-5 {
  padding-bottom: 1.25rem !important
}

.pl-1 {
  padding-left: 0.25rem !important
}

.pl-3 {
  padding-left: 0.75rem !important
}

.text-left {
  text-align: left !important
}

.text-center {
  text-align: center !important
}

.font-sans {
  font-family: ui-sans-serif, system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji" !important
}

.text-xs {
  font-size: 0.75rem !important;
  line-height: 1rem !important
}

.text-sm {
  font-size: 0.875rem !important;
  line-height: 1.25rem !important
}

.text-base {
  font-size: 1rem !important;
  line-height: 1.5rem !important
}

.text-lg {
  font-size: 1.125rem !important;
  line-height: 1.75rem !important
}

.text-xl {
  font-size: 1.25rem !important;
  line-height: 1.75rem !important
}

.text-2xl {
  font-size: 1.5rem !important;
  line-height: 2rem !important
}

.text-3xl {
  font-size: 1.875rem !important;
  line-height: 2.25rem !important
}

.font-light {
  font-weight: 300 !important
}

.font-normal {
  font-weight: 400 !important
}

.font-medium {
  font-weight: 500 !important
}

.font-semibold {
  font-weight: 600 !important
}

.font-bold {
  font-weight: 700 !important
}

.uppercase {
  text-transform: uppercase !important
}

.italic {
  font-style: italic !important
}

.leading-4 {
  line-height: 1rem !important
}

.leading-5 {
  line-height: 1.25rem !important
}

.leading-none {
  line-height: 1 !important
}

.leading-tight {
  line-height: 1.25 !important
}

.text-black {
  --tw-text-opacity: 1 !important;
  color: rgba(0, 0, 0, var(--tw-text-opacity)) !important
}

.text-white {
  --tw-text-opacity: 1 !important;
  color: rgba(255, 255, 255, var(--tw-text-opacity)) !important
}

.text-gray-400 {
  --tw-text-opacity: 1 !important;
  color: rgba(156, 163, 175, var(--tw-text-opacity)) !important
}

.text-gray-500 {
  --tw-text-opacity: 1 !important;
  color: rgba(107, 114, 128, var(--tw-text-opacity)) !important
}

.text-gray-600 {
  --tw-text-opacity: 1 !important;
  color: rgba(75, 85, 99, var(--tw-text-opacity)) !important
}

.text-gray-700 {
  --tw-text-opacity: 1 !important;
  color: rgba(55, 65, 81, var(--tw-text-opacity)) !important
}

.text-gray-800 {
  --tw-text-opacity: 1 !important;
  color: rgba(31, 41, 55, var(--tw-text-opacity)) !important
}

.text-gray-900 {
  --tw-text-opacity: 1 !important;
  color: rgba(17, 24, 39, var(--tw-text-opacity)) !important
}

.text-red-400 {
  --tw-text-opacity: 1 !important;
  color: rgba(248, 113, 113, var(--tw-text-opacity)) !important
}

.text-red-500 {
  --tw-text-opacity: 1 !important;
  color: rgba(239, 68, 68, var(--tw-text-opacity)) !important
}

.text-dark-gray {
  --tw-text-opacity: 1 !important;
  color: rgba(51, 51, 51, var(--tw-text-opacity)) !important
}

.text-orange-400 {
  --tw-text-opacity: 1 !important;
  color: rgba(247, 172, 60, var(--tw-text-opacity)) !important
}

.text-cyan-400 {
  --tw-text-opacity: 1 !important;
  color: rgba(0, 184, 214, var(--tw-text-opacity)) !important
}

.text-green-blue-400 {
  --tw-text-opacity: 1 !important;
  color: rgba(10, 180, 160, var(--tw-text-opacity)) !important
}

.hover\:text-white:hover {
  --tw-text-opacity: 1 !important;
  color: rgba(255, 255, 255, var(--tw-text-opacity)) !important
}

.hover\:text-gray-200:hover {
  --tw-text-opacity: 1 !important;
  color: rgba(229, 231, 235, var(--tw-text-opacity)) !important
}

.underline {
  text-decoration: underline !important
}

.placeholder-gray-400::-webkit-input-placeholder {
  --tw-placeholder-opacity: 1 !important;
  color: rgba(156, 163, 175, var(--tw-placeholder-opacity)) !important
}

.placeholder-gray-400::-moz-placeholder {
  --tw-placeholder-opacity: 1 !important;
  color: rgba(156, 163, 175, var(--tw-placeholder-opacity)) !important
}

.placeholder-gray-400:-ms-input-placeholder {
  --tw-placeholder-opacity: 1 !important;
  color: rgba(156, 163, 175, var(--tw-placeholder-opacity)) !important
}

.placeholder-gray-400::-ms-input-placeholder {
  --tw-placeholder-opacity: 1 !important;
  color: rgba(156, 163, 175, var(--tw-placeholder-opacity)) !important
}

.placeholder-gray-400::placeholder {
  --tw-placeholder-opacity: 1 !important;
  color: rgba(156, 163, 175, var(--tw-placeholder-opacity)) !important
}

.opacity-0 {
  opacity: 0 !important
}

.opacity-50 {
  opacity: 0.5 !important
}

.opacity-70 {
  opacity: 0.7 !important
}

.opacity-100 {
  opacity: 1 !important
}

.hover\:opacity-100:hover {
  opacity: 1 !important
}

*, ::before, ::after {
  --tw-shadow: 0 0 #0000
}

.shadow-sm {
  --tw-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.05) !important;
  -webkit-box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow) !important;
          box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow) !important
}

.shadow-md {
  --tw-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1) !important;
  -webkit-box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow) !important;
          box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow) !important
}

.shadow-lg {
  --tw-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05) !important;
  -webkit-box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow) !important;
          box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow) !important
}

.outline-none {
  outline: 2px solid transparent !important;
  outline-offset: 2px !important
}

.focus\:outline-none:focus {
  outline: 2px solid transparent !important;
  outline-offset: 2px !important
}

*, ::before, ::after {
  --tw-ring-inset: var(--tw-empty,/*!*/ /*!*/);
  --tw-ring-offset-width: 0px;
  --tw-ring-offset-color: #fff;
  --tw-ring-color: rgba(59, 130, 246, 0.5);
  --tw-ring-offset-shadow: 0 0 #0000;
  --tw-ring-shadow: 0 0 #0000
}

.filter {
  --tw-blur: var(--tw-empty,/*!*/ /*!*/) !important;
  --tw-brightness: var(--tw-empty,/*!*/ /*!*/) !important;
  --tw-contrast: var(--tw-empty,/*!*/ /*!*/) !important;
  --tw-grayscale: var(--tw-empty,/*!*/ /*!*/) !important;
  --tw-hue-rotate: var(--tw-empty,/*!*/ /*!*/) !important;
  --tw-invert: var(--tw-empty,/*!*/ /*!*/) !important;
  --tw-saturate: var(--tw-empty,/*!*/ /*!*/) !important;
  --tw-sepia: var(--tw-empty,/*!*/ /*!*/) !important;
  --tw-drop-shadow: var(--tw-empty,/*!*/ /*!*/) !important;
  -webkit-filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow) !important;
          filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow) !important
}

.blur {
  --tw-blur: blur(8px) !important
}

.drop-shadow {
  --tw-drop-shadow: drop-shadow(0 1px 2px rgba(0, 0, 0, 0.1)) drop-shadow(0 1px 1px rgba(0, 0, 0, 0.06)) !important
}

.grayscale {
  --tw-grayscale: grayscale(100%) !important
}

.backdrop-filter {
  --tw-backdrop-blur: var(--tw-empty,/*!*/ /*!*/) !important;
  --tw-backdrop-brightness: var(--tw-empty,/*!*/ /*!*/) !important;
  --tw-backdrop-contrast: var(--tw-empty,/*!*/ /*!*/) !important;
  --tw-backdrop-grayscale: var(--tw-empty,/*!*/ /*!*/) !important;
  --tw-backdrop-hue-rotate: var(--tw-empty,/*!*/ /*!*/) !important;
  --tw-backdrop-invert: var(--tw-empty,/*!*/ /*!*/) !important;
  --tw-backdrop-opacity: var(--tw-empty,/*!*/ /*!*/) !important;
  --tw-backdrop-saturate: var(--tw-empty,/*!*/ /*!*/) !important;
  --tw-backdrop-sepia: var(--tw-empty,/*!*/ /*!*/) !important;
  -webkit-backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia) !important;
          backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia) !important
}

.transition-all {
  -webkit-transition-property: all !important;
  transition-property: all !important;
  -webkit-transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1) !important;
          transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1) !important;
  -webkit-transition-duration: 150ms !important;
          transition-duration: 150ms !important
}

.transition {
  -webkit-transition-property: background-color, border-color, color, fill, stroke, opacity, -webkit-box-shadow, -webkit-transform, -webkit-filter, -webkit-backdrop-filter !important;
  transition-property: background-color, border-color, color, fill, stroke, opacity, -webkit-box-shadow, -webkit-transform, -webkit-filter, -webkit-backdrop-filter !important;
  transition-property: background-color, border-color, color, fill, stroke, opacity, box-shadow, transform, filter, backdrop-filter !important;
  transition-property: background-color, border-color, color, fill, stroke, opacity, box-shadow, transform, filter, backdrop-filter, -webkit-box-shadow, -webkit-transform, -webkit-filter, -webkit-backdrop-filter !important;
  -webkit-transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1) !important;
          transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1) !important;
  -webkit-transition-duration: 150ms !important;
          transition-duration: 150ms !important
}

.transition-colors {
  -webkit-transition-property: background-color, border-color, color, fill, stroke !important;
  transition-property: background-color, border-color, color, fill, stroke !important;
  -webkit-transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1) !important;
          transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1) !important;
  -webkit-transition-duration: 150ms !important;
          transition-duration: 150ms !important
}

.transition-opacity {
  -webkit-transition-property: opacity !important;
  transition-property: opacity !important;
  -webkit-transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1) !important;
          transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1) !important;
  -webkit-transition-duration: 150ms !important;
          transition-duration: 150ms !important
}

.duration-200 {
  -webkit-transition-duration: 200ms !important;
          transition-duration: 200ms !important
}

.duration-300 {
  -webkit-transition-duration: 300ms !important;
          transition-duration: 300ms !important
}

.duration-700 {
  -webkit-transition-duration: 700ms !important;
          transition-duration: 700ms !important
}

.ease-out {
  -webkit-transition-timing-function: cubic-bezier(0, 0, 0.2, 1) !important;
          transition-timing-function: cubic-bezier(0, 0, 0.2, 1) !important
}

.ease-in-out {
  -webkit-transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1) !important;
          transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1) !important
}

@media (min-width: 640px) {
}

@media (min-width: 768px) {
  .md\:bottom-0 {
    bottom: 0px !important
  }

  .md\:left-auto {
    left: auto !important
  }

  .md\:order-last {
    -webkit-box-ordinal-group: 10000 !important;
        -ms-flex-order: 9999 !important;
            order: 9999 !important
  }

  .md\:my-0 {
    margin-top: 0px !important;
    margin-bottom: 0px !important
  }

  .md\:mt-0 {
    margin-top: 0px !important
  }

  .md\:mt-1 {
    margin-top: 0.25rem !important
  }

  .md\:mr-2 {
    margin-right: 0.5rem !important
  }

  .md\:mr-4 {
    margin-right: 1rem !important
  }

  .md\:mb-0 {
    margin-bottom: 0px !important
  }

  .md\:mb-3 {
    margin-bottom: 0.75rem !important
  }

  .md\:mb-12 {
    margin-bottom: 3rem !important
  }

  .md\:block {
    display: block !important
  }

  .md\:inline {
    display: inline !important
  }

  .md\:flex {
    display: -webkit-box !important;
    display: -ms-flexbox !important;
    display: flex !important
  }

  .md\:hidden {
    display: none !important
  }

  .md\:h-36 {
    height: 9rem !important
  }

  .md\:h-full {
    height: 100% !important
  }

  .md\:w-28 {
    width: 7rem !important
  }

  .md\:w-52 {
    width: 13rem !important
  }

  .md\:w-72 {
    width: 18rem !important
  }

  .md\:w-4\/5 {
    width: 80% !important
  }

  .md\:w-full {
    width: 100% !important
  }

  .md\:max-w-xs {
    max-width: 20rem !important
  }

  .md\:flex-3 {
    -webkit-box-flex: 3 !important;
        -ms-flex: 3 1 0% !important;
            flex: 3 1 0% !important
  }

  .md\:grid-cols-4 {
    grid-template-columns: repeat(4, minmax(0, 1fr)) !important
  }

  .md\:flex-row {
    -webkit-box-orient: horizontal !important;
    -webkit-box-direction: normal !important;
        -ms-flex-direction: row !important;
            flex-direction: row !important
  }

  .md\:items-center {
    -webkit-box-align: center !important;
        -ms-flex-align: center !important;
            align-items: center !important
  }

  .md\:gap-2 {
    gap: 0.5rem !important
  }

  .md\:p-0 {
    padding: 0px !important
  }

  .md\:p-4 {
    padding: 1rem !important
  }

  .md\:px-4 {
    padding-left: 1rem !important;
    padding-right: 1rem !important
  }

  .md\:py-0 {
    padding-top: 0px !important;
    padding-bottom: 0px !important
  }

  .md\:pl-3 {
    padding-left: 0.75rem !important
  }

  .md\:text-left {
    text-align: left !important
  }

  .md\:text-sm {
    font-size: 0.875rem !important;
    line-height: 1.25rem !important
  }

  .md\:text-base {
    font-size: 1rem !important;
    line-height: 1.5rem !important
  }

  .md\:text-lg {
    font-size: 1.125rem !important;
    line-height: 1.75rem !important
  }

  .md\:text-3xl {
    font-size: 1.875rem !important;
    line-height: 2.25rem !important
  }

  .md\:leading-5 {
    line-height: 1.25rem !important
  }

  .md\:text-gray-800 {
    --tw-text-opacity: 1 !important;
    color: rgba(31, 41, 55, var(--tw-text-opacity)) !important
  }
}

@media (min-width: 1024px) {
}

@media (min-width: 1280px) {
}

@media (min-width: 1536px) {
}
/* purgecss start ignore */

/* Document
 * ========================================================================== */

/**
 * 1. Correct the line height in all browsers.
 * 2. Prevent adjustments of font size after orientation changes in
 *    IE on Windows Phone and in iOS.
 */

html {
  line-height: 1.15; /* 1 */
  -ms-text-size-adjust: 100%; /* 2 */
  -webkit-text-size-adjust: 100%; /* 2 */
}

/* Sections
 * ========================================================================== */

/**
 * Correct the font size and margin on `h1` elements within `section` and
 * `article` contexts in Chrome, Edge, Firefox, and Safari.
 */

h1 {
  font-size: 2em;
  margin: 0.67em 0;
}

/* Grouping content
 * ========================================================================== */

/**
 * Remove the margin on nested lists in Chrome, Edge, IE, and Safari.
 */

dl dl,
dl ol,
dl ul,
ol dl,
ul dl {
  margin: 0;
}

/**
 * 1. Add the correct box sizing in Firefox.
 * 2. Show the overflow in Edge 18- and IE.
 */

hr {
  -webkit-box-sizing: content-box;
          box-sizing: content-box; /* 1 */
  height: 0; /* 1 */
}

/**
 * 1. Correct the inheritance and scaling of font size in all browsers.
 * 2. Correct the odd `em` font sizing in all browsers.
 */

pre {
  font-family: monospace, monospace; /* 1 */
  font-size: 1em; /* 2 */
}

/* Text-level semantics
 * ========================================================================== */

/**
 * Add the correct text decoration in Edge 18-, IE, and Safari.
 */

abbr[title] {
  text-decoration: underline;
  -webkit-text-decoration: underline dotted;
          text-decoration: underline dotted;
}

/**
 * Add the correct font weight in Chrome, Edge, and Safari.
 */

b,
strong {
  font-weight: bolder;
}

/**
 * 1. Correct the inheritance and scaling of font size in all browsers.
 * 2. Correct the odd `em` font sizing in all browsers.
 */

code,
kbd,
samp {
  font-family: monospace, monospace; /* 1 */
  font-size: 1em; /* 2 */
}

/**
 * Add the correct font size in all browsers.
 */

small {
  font-size: 80%;
}

/* Embedded content
 * ========================================================================== */

/* Forms
 * ========================================================================== */

/**
 * Remove the margin on controls in Safari.
 */

button,
input,
select {
  margin: 0;
}

/**
 * 1. Show the overflow in IE.
 * 2. Remove the inheritance of text transform in Edge 18-, Firefox, and IE.
 */

button {
  text-transform: none; /* 2 */
}

/**
 * Correct the inability to style buttons in iOS and Safari.
 */

button,
[type="button"],
[type="reset"],
[type="submit"] {
  -webkit-appearance: button;
}

/**
 * Correct the padding in Firefox.
 */

fieldset {
  padding: 0.35em 0.75em 0.625em;
}

/**
 * 1. Add the correct display in Edge 18- and IE.
 * 2. Add the correct vertical alignment in Chrome, Edge, and Firefox.
 */

progress {
  vertical-align: baseline; /* 2 */
}

/**
 * Remove the inheritance of text transform in Firefox.
 */

select {
  text-transform: none;
}

/**
 * 1. Remove the margin in Firefox and Safari.
 * 2. Remove the default vertical scrollbar in IE.
 */

textarea {
  margin: 0; /* 1 */
}

/**
 * 1. Correct the odd appearance in Chrome, Edge, and Safari.
 * 2. Correct the outline style in Safari.
 */

[type="search"] {
  -webkit-appearance: textfield; /* 1 */
  outline-offset: -2px; /* 2 */
}

/**
 * Correct the cursor style of increment and decrement buttons in Safari.
 */

::-webkit-inner-spin-button,
::-webkit-outer-spin-button {
  height: auto;
}

/**
 * Correct the text style of placeholders in Chrome, Edge, and Safari.
 */

::-webkit-input-placeholder {
  color: inherit;
  opacity: 0.54;
}

/**
 * Remove the inner padding in Chrome, Edge, and Safari on macOS.
 */

::-webkit-search-decoration {
  -webkit-appearance: none;
}

/**
 * 1. Correct the inability to style upload buttons in iOS and Safari.
 * 2. Change font properties to `inherit` in Safari.
 */

::-webkit-file-upload-button {
  -webkit-appearance: button; /* 1 */
  font: inherit; /* 2 */
}

/**
 * Remove the inner border and padding of focus outlines in Firefox.
 */

::-moz-focus-inner {
  border-style: none;
  padding: 0;
}

/**
 * Restore the focus outline styles unset by the previous rule in Firefox.
 */

:-moz-focusring {
  outline: 1px dotted ButtonText;
}

/**
 * Remove the additional :invalid styles in Firefox.
 */

:-moz-ui-invalid {
  box-shadow: none;
}

/* Interactive
 * ========================================================================== */

/*
 * Add the correct display in Edge 18- and IE.
 */

details {
  display: block;
}

/*
 * Add the correct styles in Edge 18-, IE, and Safari.
 */

dialog {
  background-color: white;
  border: solid;
  color: black;
  display: block;
  height: -moz-fit-content;
  height: -webkit-fit-content;
  height: fit-content;
  left: 0;
  margin: auto;
  padding: 1em;
  position: absolute;
  right: 0;
  width: -moz-fit-content;
  width: -webkit-fit-content;
  width: fit-content;
}

dialog:not([open]) {
  display: none;
}

/*
 * Add the correct display in all browsers.
 */

summary {
  display: list-item;
}

/* Scripting
 * ========================================================================== */

/* User interaction
 * ========================================================================== */

/****** Elad Shechter's RESET *******/

/*** box sizing border-box for all elements ***/

*,
*::before,
*::after {
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
}

a {
  text-decoration: none;
  color: inherit;
  cursor: pointer;
}

button {
  background-color: transparent;
  color: inherit;
  border-width: 0;
  padding: 0;
  cursor: pointer;
}

figure {
  margin: 0;
}

input::-moz-focus-inner {
  border: 0;
  padding: 0;
  margin: 0;
}

ul,
ol,
dd {
  margin: 0;
  padding: 0;
  list-style: none;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
  font-size: inherit;
  font-weight: inherit;
}

p {
  margin: 0;
}

cite {
  font-style: normal;
}

fieldset {
  border-width: 0;
  padding: 0;
  margin: 0;
}

/* purgecss end ignore */

/* purgecss start ignore */

* {
  outline: none;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
}

body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: 'Lato', sans-serif;
  color: #999;
  height: auto !important;
  min-height: 100% !important;
  background: #f9f9f9;
}

strong {
  font-weight: bold;
}

em {
  font-style: italic;
}

.ReactModal__Body--open {
  overflow-y: hidden;
}

/* purgecss end ignore */

